


/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */



(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {


				// JavaScript to be fired on all pages
				$('document').ready(function(){

					function scrollToDiv(element,navheight){
						var offset = element.offset();
						var offsetTop = offset.top;
						var totalScroll = offsetTop-navheight;

						// $('body,html').animate({
						// 	scrollTop: totalScroll
						// }, 250);
					}


					$(function(){
						var hasBeenTrigged = false;
						$(window).scroll(function() {

							var scrolled = $(this).scrollTop();
							var section = $(window).height() - 10;

							if ($(this).scrollTop() >= 100 && !hasBeenTrigged) {
								jQuery('header.banner').addClass('bg-active');
								hasBeenTrigged = true;
							}

							if ($(this).scrollTop() < 100 && hasBeenTrigged) {
								$('header.banner').removeClass('bg-active');
								hasBeenTrigged = false;
							}


							if($('body').hasClass('home')){
								if ($(this).scrollTop() < 360) {
									$('.hero-menu-container+section').css('top', -140 + ($(window).scrollTop() / 3));
								}
							}

							if($('body').hasClass('single-case')){
								if ($(this).scrollTop() < 360) {
									$('.hero-menu-container+section').css('top', -140 + ($(window).scrollTop() / 3));
								}
							}



							if($('body').hasClass('offert')){
								if(scrolled > section){
									$('.offertLink').removeClass('active');
									$('#vadLink-a').addClass('active');
									if(scrolled > section*2){
										$('.offertLink').removeClass('active');
										$('#hurLink-a').addClass('active');
										if(scrolled > section*3){
											$('.offertLink').removeClass('active');
											$('#narLink-a').addClass('active');
										}
									}
								}else{
									$('.offertLink').removeClass('active');
									$('#vemLink-a').addClass('active');
								}
							}
						});
					});

					/* Movements on scroll (Navbar background + movement of the 2 boxes @ front page */

					if($('body').hasClass('offert')){

						$('.offertLink').click(function(){
							var el = $(this).attr('href');
							var elWrapped = $(el);
							scrollToDiv(elWrapped, 0);
							return false;
						});

						$(function() {
							$.scrollify({
								section : ".offert-row",
							});

						});
						/* ** Autoscroll ** */

					}

					/* Resize  Custom Image */

					if($('.custom-height').length > 0) {
						$('.custom-height').each(function(){
							var el = $(this);
							var ymod = el.data('ymod');
							y = ymod;
							if(el.parents('.container').length > 0){ // Bilden är 100% i en .container på 1142px bredd
								x = 1140;
								if(el.parents('.col-sm-8').length > 0){  // Bilden är 2/3 och i en .container på 1142px bredd
									x = 750;
								}
								if(el.parents('.col-sm-4').length > 0){  // Bilden är 1/3 och i en .container på 1142px bredd
									x = 360;
								}
								if(el.parents('.col-sm-6').length > 0){  // Bilden är 1/2 och i en .container på 1142px bredd
									x = 555;
								}

							}else{
								x = 1440;
								y = ymod;
								if(el.parents('.col-sm-4').length > 0){  // Bilden är 1/3 och i en .container på 1142px bredd
									x = (x/3)-15;
								}
								if(el.parents('.col-sm-6').length > 0){  // Bilden är 1/3 och i en .container på 1142px bredd
									x = (x/2)-15;
								}
								if(el.parents('.col-sm-8').length > 0){  // Bilden är 1/3 och i en .container på 1142px bredd
									x = (x/3)*2;
									x = x - 15;
								}
							}
							var ratio = y/x;
							el.attr('data-ratio', ratio);
						});

						if($(window).width() < 1200){
							customHeightResponsive();
						}
					}

					function customHeightResponsive(){
						if($(window).width() < 1200){
							$('.custom-height').each(function(){
								var el = $(this);
								var curX = el.width();
								var ratio = el.data('ratio');
								el.height(ratio*curX);
							});
						}else{
							$('.custom-height').each(function(){
								var el = $(this);
								var ymod = el.data('ymod');
								el.height(ymod);
							});
						}
					}

					$( window ).resize(function() {
						customHeightResponsive();
					});

					/* Resize */

					if ($('.counter').length > 0) {
						var scrolled = 0;
						var offset = $('.countup-block').offset().top - ($(window).height()*0.50);

						$(window).scroll(function() {
							var scrollTop = $(window).scrollTop();
							if ( scrollTop > offset && scrolled == 0) {

								/* CountUp Function */

								$('.counter').each(function() {
									var $this = $(this),
									countTo = $this.attr('data-count');

									$({ countNum: $this.text()}).animate({
										countNum: countTo
									},

									{
										duration: 2000,
										useEasing : true,
										  		useGrouping : true,
										  		separator : ',',
										  		decimal : ',',
										  		prefix : '',
										  		suffix : '' ,
										step: function() {
											$this.text(Math.floor(this.countNum));
										},
										complete: function() {
											$this.text(this.countNum);
										}
									});
								});

								/* CountUp Function end */

								scrolled = 1;
							}
						});

					} // If counter-class exists
				});

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function() {


				/* HOME END */
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function() {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

	})(jQuery); // Fully reference jQuery after this point.
